import axios from "axios";
import { Fragment, lazy } from "react";
import { Navigate, Route, Routes } from "react-router-dom";
import { BlocBuilder } from "react-stream-bloc";
import ChangePassword from "./admin/pages/auth/ChangePassword";
import ForgotPassword from "./admin/pages/auth/ForgotPassword";
import Login from "./admin/pages/auth/Login";
import Register from "./admin/pages/auth/Register";
import VerifyAccount from "./admin/pages/auth/VerifyAccount";
import CreditContent from "./admin/pages/credit/Credit";
import MyCreditContent from "./admin/pages/credit/MyCredit";
import HistorialAllContent from "./admin/pages/historial_all";
import HistoryContent from "./admin/pages/history";
import LineContent from "./admin/pages/line";
import OrderContent from "./admin/pages/order";
import PrivacyContent from "./admin/pages/Privacy";
import RequestContent from "./admin/pages/request";
import OperatorContent from "./admin/pages/user";
import { AuthState } from "./core/bloc/auth/auth_state";
import { useAuthBloc } from "./core/provider/Provider";
// Admin
const Admin = lazy(() => import("./admin/pages/Admin"));
const Dashboard = lazy(() => import("./admin/pages/Dashboard"));
const Faq = lazy(() => import("./admin/pages/Faq"));
const HelpCenter = lazy(() => import("./admin/pages/HelpCenter"));
const Profile = lazy(() => import("./admin/pages/Profile"));
const ProfileInformation = lazy(
  () => import("./admin/pages/ProfileInformation")
);
const ProfilePassword = lazy(() => import("./admin/pages/ProfilePassword"));

const UserLineContent = lazy(() => import("./admin/pages/user_line/UserLine"));

// Core
const Forbidden = lazy(() => import("./core/pages/Forbidden"));
const NotFound = lazy(() => import("./core/pages/NotFound"));
const UnderConstructions = lazy(
  () => import("./core/pages/UnderConstructions")
);

// Landing
const Landing = lazy(() => import("./landing/pages/Landing"));

const AppRoutes = () => {
  const bloc = useAuthBloc();

  return (
    <Fragment>
      <BlocBuilder
        bloc={bloc}
        builder={(state: AuthState) => {
          if (state.token) {
            axios.defaults.headers.common[
              "Authorization"
            ] = `Bearer ${state.token}`;

            return (
              <Routes>
                <Route path="/" element={<Admin auth={bloc} state={state} />}>
                  <Route
                    path=""
                    element={<Dashboard bloc={bloc} state={state} />}
                  />
                  <Route path="questions" element={<Faq />} />
                  <Route path="help" element={<HelpCenter />} />
                  <Route
                    path="profile"
                    element={<Profile bloc={bloc} state={state} />}
                  >
                    <Route
                      path=""
                      element={
                        <ProfileInformation
                          data={state.user}
                          state={state}
                          bloc={bloc}
                        />
                      }
                    />
                    <Route path="password" element={<ProfilePassword />} />
                  </Route>
                  {state.user?.role === "admin" ? (
                    <Route path="lines" element={<LineContent />} />
                  ) : undefined}
                  {state.user?.role === "admin" ? (
                    <Route path="credits" element={<CreditContent />} />
                  ) : undefined}
                  {state.user?.role === "admin" ? (
                    <Route path="orders" element={<OrderContent />} />
                  ) : undefined}
                  {state.user?.role === "admin" ? (
                    <Route path="users" element={<OperatorContent />} />
                  ) : undefined}

                  {state.user?.role === "admin" ? (
                    <Route path="user-lines" element={<UserLineContent />} />
                  ) : undefined}
                  {state.user?.role === "admin" ? (
                    <Route
                      path="historial-all"
                      element={<HistorialAllContent />}
                    />
                  ) : undefined}
                  <Route
                    path="my-credits"
                    element={<MyCreditContent state={state} />}
                  />
                  <Route path="requests" element={<RequestContent />} />
                  <Route path="historial" element={<HistoryContent />} />
                </Route>
                <Route path="verify/:token" element={<VerifyAccount />} />
                <Route
                  path="under-construction"
                  element={<UnderConstructions />}
                />
                <Route path="/privacy" element={<PrivacyContent />} />
                <Route path="403" element={<Forbidden />} />
                <Route path="404" element={<NotFound />} />
                <Route path="*" element={<Navigate to={`/`} replace />} />
              </Routes>
            );
          }

          return (
            <Routes>
              <Route path="/" element={<Landing />} />
              <Route path="forgot" element={<ForgotPassword />} />
              <Route
                path="change-password/:token"
                element={<ChangePassword />}
              />
              <Route path="verify/:token" element={<VerifyAccount />} />
              <Route path="login" element={<Login />} />
              <Route path="signup" element={<Register />} />
              <Route
                path="under-construction"
                element={<UnderConstructions />}
              />
              <Route path="/privacy" element={<PrivacyContent />} />
              <Route path="403" element={<Forbidden />} />
              <Route path="404" element={<NotFound />} />
              <Route path="*" element={<Navigate to={`/login`} replace />} />
            </Routes>
          );
        }}
      />
    </Fragment>
  );
};

export default AppRoutes;
