import { Theme } from "@mui/material";
import { makeStyles } from "mui-styles";

export const useStyles = makeStyles((theme: Theme) => ({
  outlined: {
    height: 44,
    border: "1px solid #E3E6E8",
    boxSizing: "border-box",
    borderRadius: 20,
    boxShadow: "0px 8px 24px -8px rgba(78, 125, 150, 0.24)",
    "& .MuiButton-label": {
      fontSize: 16,
      color: "white",
      fontWeight: "bold",
      textAlign: "center",
      fontStyle: "normal",
    },
    "&:hover": {
      backgroundColor: "#E3E6E8",
      border: "2px solid #E3E6E8",
    },
    "&:hover .MuiButton-label": {},
  },
  contained: {
    height: 44,
    background: `${theme.palette.primary}`,
    boxSizing: "border-box",
    color: "#fff",
    borderRadius: 20,
    boxShadow: "0px 4px 8px rgba(0, 153, 102, 0.24)",
    "& .MuiButton-label": {
      fontSize: 16,
      color: "white",
      fontWeight: "bold",
      textAlign: "center",
      fontStyle: "normal",
    },
    "&:hover": {
      backgroundColor: `${theme.palette.primary}`,
      opacity: 0.9,
    },
    "&:hover .MuiButton-label": {},
  },
  text: {
    height: 44,
    boxSizing: "border-box",
    borderRadius: 20,
    boxShadow: "0px 8px 24px -8px rgba(78, 125, 150, 0.24)",
    "& .MuiButton-label": {
      fontSize: 16,
      color: "white",
      fontWeight: "bold",
      textAlign: "center",
      fontStyle: "normal",
    },
    "&:hover": {
      backgroundColor: "#F3F3F3",
    },
    "&:hover .MuiButton-label": {},
  },
  cancel: {
    height: 44,
    border: "2px solid #fff",
    boxSizing: "border-box",
    color: "#595B5C",
    borderRadius: 20,
    boxShadow: "0px 8px 24px -8px rgba(78, 125, 150, 0.24)",
    "& .MuiButton-label": {
      fontSize: 16,
      color: "white",
      fontWeight: "bold",
      textAlign: "center",
      fontStyle: "normal",
    },
    "&:hover": {
      backgroundColor: "#E3E6E8",
      border: "2px solid #E3E6E8",
    },
    "&:hover .MuiButton-label": {},
  },
  delete: {
    height: 44,
    background: `${theme.palette.error.main}`,
    boxSizing: "border-box",
    color: "#fff",
    borderRadius: 20,
    boxShadow: "0px 4px 8px rgba(255, 51, 66, 0.24)",

    "& .MuiButton-label": {
      fontSize: 16,
      color: "white",
      fontWeight: "bold",
      textAlign: "center",
      fontStyle: "normal",
    },
    "&:hover": {
      backgroundColor: `${theme.palette.error.main}`,
      opacity: 0.7,
    },
    "&:hover .MuiButton-label": {},
  },
}));
