import ReactDOM from "react-dom/client";
import { HelmetProvider } from "react-helmet-async";
import { BrowserRouter } from "react-router-dom";
import App from "./App";
import "./styles/index.css";

import "moment-timezone";
import "moment/locale/es";
import "./i18n";

import axios from "axios";
import Moment from "react-moment";

axios.defaults.baseURL = process.env.REACT_APP_HOST_API_URL;

// Set the timezone for every instance.
Moment.globalTimezone = "Europe/Madrid";

// Set the output timezone for local for every instance.
Moment.globalLocal = true;

Moment.globalLocale = "es";

const root = ReactDOM.createRoot(document.getElementById("root")!);

root.render(
  <BrowserRouter>
    <HelmetProvider>
      <App />
    </HelmetProvider>
  </BrowserRouter>
);