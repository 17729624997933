import Container from "@mui/material/Container";
import React from "react";
import AdminAppBar from "../components/AdminAppBar";
import AdminToolbar from "../components/AdminToolbar";

const PrivacyContent = () => {
  return (
    <React.Fragment>
      <AdminAppBar>
        <AdminToolbar />
      </AdminAppBar>
      <Container maxWidth="sm">
        <div>
          <h2 id="isPasted" data-pm-slice="1 1 []">
            Pol&iacute;tica de Privacidad
          </h2>
          <h2 id="isPasted" data-pm-slice="1 1 []">Pol&iacute;tica de Privacidad</h2>
          <h3>Introducci&oacute;n</h3>
          <p>
            En&nbsp;<strong>Gesti&oacute;n M&oacute;vil</strong>, estamos
            comprometidos con la protecci&oacute;n de la informaci&oacute;n
            personal de nuestros usuarios. Valoramos su confianza y trabajamos
            para garantizar la transparencia en el manejo de sus datos.
            Recomendamos revisar esta p&aacute;gina peri&oacute;dicamente, ya
            que podr&iacute;a actualizarse para reflejar cambios en nuestras
            pr&aacute;cticas o en las normativas aplicables.
          </p>
          <h3>Informaci&oacute;n que Recopilamos</h3>
          <p>
            Actualmente, la app&nbsp;
            <strong>Gesti&oacute;n M&oacute;vil</strong>&nbsp;no recopila ni
            almacena ning&uacute;n tipo de informaci&oacute;n personal de sus
            usuarios. Esto incluye datos de identificaci&oacute;n,
            informaci&oacute;n de contacto y datos relacionados con el uso de la
            aplicaci&oacute;n.
          </p>
          <p>
            En caso de que en el futuro se necesite recopilar alg&uacute;n tipo
            de informaci&oacute;n para mejorar los servicios ofrecidos, se
            solicitar&aacute; su consentimiento expreso y se
            proporcionar&aacute;n detalles claros sobre el tipo de datos
            recopilados, su finalidad y c&oacute;mo se proteger&aacute;n.
          </p>
          <h3>Control de su Informaci&oacute;n Personal</h3>
          <p>
            Aunque actualmente no recopilamos informaci&oacute;n personal, si
            esto cambia, usted podr&aacute; restringir la recopilaci&oacute;n y
            uso de su informaci&oacute;n personal de las siguientes maneras:
          </p>
          <ul data-spread="false">
            <li>
              <p>
                Optando por no proporcionar datos personales en formularios
                opcionales.
              </p>
            </li>
            <li>
              <p>
                Marcando o desmarcando opciones para recibir comunicaciones,
                como boletines o correos publicitarios.
              </p>
            </li>
            <li>
              <p>
                Cancelando la suscripci&oacute;n a cualquier comunicaci&oacute;n
                promocional en cualquier momento a trav&eacute;s del enlace de
                "Cancelar suscripci&oacute;n" incluido en cada mensaje.
              </p>
            </li>
          </ul>
          <p>
            Nos comprometemos a no vender, ceder ni distribuir su
            informaci&oacute;n personal sin su consentimiento expreso, salvo que
            sea requerido por una orden judicial u otra obligaci&oacute;n legal.
          </p>
          <h3>Seguridad</h3>
          <p>
            En&nbsp;<strong>Gesti&oacute;n M&oacute;vil</strong>, implementamos
            medidas de seguridad avanzadas para proteger la informaci&oacute;n
            contra accesos no autorizados, manipulaciones o divulgaciones
            indebidas. Algunas de estas medidas incluyen:
          </p>
          <ul data-spread="false">
            <li>
              <p>Cifrado de datos mediante protocolos seguros.</p>
            </li>
            <li>
              <p>
                Monitoreo constante de nuestros sistemas para prevenir
                vulnerabilidades.
              </p>
            </li>
            <li>
              <p>
                Actualizaciones regulares de seguridad en nuestras plataformas.
              </p>
            </li>
          </ul>
          <p>
            Aunque hacemos todo lo posible para garantizar la seguridad, es
            importante que los usuarios tambi&eacute;n tomen precauciones, como
            no compartir informaci&oacute;n confidencial a trav&eacute;s de
            canales no seguros.
          </p>
          <h3>Cambios en la Pol&iacute;tica de Privacidad</h3>
          <p>
            Nos reservamos el derecho de actualizar esta Pol&iacute;tica de
            Privacidad en cualquier momento. Cualquier cambio ser&aacute;
            publicado en esta p&aacute;gina y entrar&aacute; en vigor
            inmediatamente despu&eacute;s de su publicaci&oacute;n.
            Notificaremos a los usuarios sobre cambios significativos mediante
            un aviso en la aplicaci&oacute;n o por otros medios apropiados.
          </p>
          <h3>&nbsp;</h3>
          <p>
            Gracias por confiar en&nbsp;
            <strong>Gesti&oacute;n M&oacute;vil</strong>.
          </p>
        </div>
      </Container>
    </React.Fragment>
  );
};

export default PrivacyContent;
