import CheckCircle from "@mui/icons-material/CheckCircle";
import PhoneIcon from "@mui/icons-material/PhoneRounded";
import ErrorCircle from "@mui/icons-material/RemoveCircle";
import { Avatar, Card, Chip, Grid, Typography } from "@mui/material";
import { Box } from "@mui/system";
import { format } from "date-fns";
import { useEffect, useState } from "react";
import UiButton from "src/components/Buttons";
import ContainerContent from "src/components/Container/Content";
import { TextLabel } from "src/components/Label";
import UiLoadingButton from "src/components/LoadingButtons";
import { Historial, Recharge } from "src/core/models/request_model";
import HistoryService from "src/core/services/history_service";
import { HistorialAllContentProps } from "src/core/types/content_type";
import useWindowDimensions from "src/core/utils/dimensions";

const RequestRechargeContent = ({
  setOpen,
  data,
  bloc
}: HistorialAllContentProps) => {
  const [historial, setHistorial] = useState<Historial>();
  const [loading, setLoading] = useState<boolean>(false);
  const [loadingCSV, setLoadingCSV] = useState<boolean>(false);
  const { width, height } = useWindowDimensions();
  const [selected, setSelected] = useState<string>();

  const [charges, setCharges] = useState<Recharge[]>([]);
  const [selectColor, setSelectColor] = useState<string | undefined>();

  const getData = async () => {
    setLoading(true);
    const resH = await bloc.getHistorialDetail(data?.id);
    if (resH) {
      setHistorial(resH);
      setCharges(resH.numbers ?? []);
    } else {
      const res = await new HistoryService().getHistory(data?.id);
      if (res.code === "success") {
        setHistorial(res.data);
        setCharges(res.data?.numbers ?? []);
        if (res.data) {
          bloc.setHistorialDetail(res.data);
        }
      }
    }

    setLoading(false);
  };

  const filterData = (type: string) => {
    const datas = historial?.numbers ?? [];
    switch (type) {
      case "complete":
        setSelectColor("#009966");
        setSelected("complete");
        setCharges(
          datas.filter((item) => item.amount_credit === item.used_credits)
        );
        break;
      case "partial":
        setSelectColor("orange");
        setSelected("partial");
        setCharges(
          datas.filter(
            (item) => item.used_credits > 0 && item.not_used_credits > 0
          )
        );
        break;
      case "error":
        setSelectColor("red");
        setSelected("error");
        setCharges(datas.filter((item) => item.error_ids > 0));
        break;
      case "unrealized":
        setSelectColor(undefined);
        setSelected("unrealized");
        setCharges(datas.filter((item) => item.used_credits === 0));
        break;
      default:
        setSelectColor(undefined);
        setSelected(undefined);
        setCharges(datas);
        break;
    }
  };

  const exportData = () => {
    setLoadingCSV(true);
    const res = historial?.numbers ?? [];
    if (res.length > 0) {
      // Convert to CSV
      const csvContent = [
        ["ID solicitud", "Fecha", "Número", "GB"], // header
        ...res.map((row) => [
          row.id,
          format(new Date(row.created_at), "dd-MM-yyyy"),
          row.number,
          row.used_credits * 10
        ]) // Data rows
      ]
        .map((e) => e.join(","))
        .join("\n");

      // Create a Blob object representing the CSV data
      const blob = new Blob([csvContent], { type: "text/csv;charset=utf-8;" });

      // Create URL and download the file
      const url = URL.createObjectURL(blob);
      const link = document.createElement("a");
      link.href = url;
      link.download = "solicitud-de-cargas.csv";
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);

      // liberate the URL once the download is complete
      URL.revokeObjectURL(url);
    }

    setLoadingCSV(false);
  };

  useEffect(() => {
    getData();
  }, []);

  return (
    <ContainerContent
      loading={loading}
      sx={{
        maxHeight: loading ? 100 : undefined
      }}
    >
      <Box
        display="flex"
        flexDirection="row"
        alignItems="center"
        justifyContent="space-between"
        justifyItems="center"
        style={{
          width: "100%"
        }}
        paddingBottom={1}
      >
        <Typography variant="h5">Detalles del historial</Typography>
      </Box>
      <Grid container spacing={2} paddingBottom={1}>
        <Grid item>
          <TextLabel label="Numeros a cargar">
            <Chip
              label={historial?.amount_numbers ?? 0}
              variant="filled"
              onClick={() => filterData("default")}
            />
          </TextLabel>
        </Grid>
        <Grid item>
          <TextLabel label="Cargas completas">
            <Chip
              label={historial?.complete_charge ?? 0}
              variant={selected === "complete" ? "filled" : "outlined"}
              color="primary"
              onClick={() => filterData("complete")}
            />
          </TextLabel>
        </Grid>
        <Grid item>
          <TextLabel label="Cargas parciales">
            <Chip
              label={historial?.partial ?? 0}
              variant={selected === "partial" ? "filled" : "outlined"}
              color="warning"
              onClick={() => filterData("partial")}
            />
          </TextLabel>
        </Grid>
        <Grid item>
          <TextLabel label="Cargas erróneas">
            <Chip
              label={historial?.error_charge ?? 0}
              variant={selected === "error" ? "filled" : "outlined"}
              color="error"
              onClick={() => filterData("error")}
            />
          </TextLabel>
        </Grid>
        <Grid item>
          <TextLabel label="Cargas no realizadas">
            <Chip
              label={historial?.unrealized_charge ?? 0}
              variant={selected === "unrealized" ? "filled" : "outlined"}
              onClick={() => filterData("unrealized")}
            />
          </TextLabel>
        </Grid>
        <Grid item>
          <TextLabel label="Créditos usados">
            <Chip
              label={historial?.used_credits ?? 0}
              variant="outlined"
              style={{
                borderWidth: 0
              }}
            />
          </TextLabel>
        </Grid>
      </Grid>
      <Box
        display="flex"
        flexDirection="column"
        alignItems="center"
        paddingLeft={2}
        sx={{
          display: "flex",
          flexDirection: "column",
          maxHeight: height - 260,
          overflow: "hidden",
          overflowY: "scroll"
        }}
      >
        <Grid
          container
          display="flex"
          flexDirection="column"
          sx={{
            mt: 1
          }}
          spacing={2}
        >
          {charges.map((item) => (
            <Card
              key={item.id}
              variant="outlined"
              sx={{
                marginBottom: 0.5
              }}
            >
              <Grid
                padding={1}
                container
                item
                spacing={2}
                alignItems="center"
                sx={{
                  width: "100%"
                }}
              >
                <Grid item xs={3} sm={1} md={1}>
                  <Avatar
                    style={{
                      color: selectColor ? "white" : undefined,
                      backgroundColor: selectColor
                    }}
                  >
                    <PhoneIcon />
                  </Avatar>
                </Grid>
                <Grid item xs={9} sm={4} md={4}>
                  <TextLabel label={`+34 ${item.number}`} sx={{ ml: 1 }} />
                </Grid>
                <Grid item xs={4} sm={2} md={2}>
                  {item.used_credits === 0 ? undefined : (
                    <TextLabel
                      label={`${item.used_credits * 10}GB`}
                      direction="row"
                      sx={{
                        alignItems: "center"
                      }}
                    >
                      <CheckCircle
                        color="primary"
                        style={{
                          width: 20,
                          marginLeft: 2
                        }}
                      />
                    </TextLabel>
                  )}
                </Grid>
                <Grid item xs={4} sm={2} md={2}>
                  {item.used_credits < item.amount_credit ? (
                    <TextLabel
                      label={
                        item.used_credits === 0
                          ? "0GB"
                          : `${
                              (Number(item.amount_credit ?? 0) -
                                Number(item.used_credits ?? 0)) *
                              10
                            }GB`
                      }
                      direction="row"
                      sx={{
                        alignItems: "center"
                      }}
                    >
                      {item.used_credits === 0 ? undefined : (
                        <ErrorCircle
                          color="error"
                          style={{
                            width: 20,
                            marginLeft: 2
                          }}
                        />
                      )}
                    </TextLabel>
                  ) : undefined}
                </Grid>
                <Grid item xs={4} sm={3} md={3}>
                  <TextLabel
                    label={`Creditos:`}
                    text={item.used_credits ?? 0}
                    direction="row"
                    sx={{
                      alignItems: "center",
                      alignContent: "end",
                      justifyContent: "end"
                    }}
                  />
                </Grid>
              </Grid>
            </Card>
          ))}
        </Grid>
      </Box>
      <Box textAlign={"center"}>
        <UiButton
          variant="outlined-primary"
          text="Cerrar"
          onClick={() => setOpen(false)}
          sx={{
            mt: 2,
            maxWidth: 100
          }}
        />
        <UiLoadingButton
          variant="contained"
          text="Exportar cargas"
          loading={loadingCSV}
          onClick={exportData}
          sx={{
            mt: 2,
            ml: 1,
            maxWidth: 250
          }}
        />
      </Box>
    </ContainerContent>
  );
};

export default RequestRechargeContent;
