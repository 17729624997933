import { useEffect, useState } from "react";
import Container from "src/components/Container";
import { UiDialog } from "src/components/Dialog";
import { Historial } from "src/core/models/request_model";
import { useHistorialBloc } from "src/core/provider/Provider";
import Content from "./components/Content";
import HistoryList from "./components/List";


const HistorialAllContent = () => {
  const bloc = useHistorialBloc();

  const [openDialog, setOpenDialog] = useState<boolean>(false);
  const [historial, setHistorial] = useState<Historial>();

  const initData = () => {
    if (bloc.historial.length === 0) {
      bloc.getHistorial();
    }
  };

  const handleEdit = (v: Historial) => {
    setHistorial(v);
    setOpenDialog(true);
  };

  useEffect(() => {
    initData();
  }, []);

  return (
    <Container helmetTitle="Historial" title="Historial de carga del usuario">
      {/*<LocalizationProvider dateAdapter={AdapterDayjs}>
        <Box display={"flex"} flexDirection="row">
          <MobileDatePicker defaultValue={dayjs(new Date())} />
        </Box>
  </LocalizationProvider> */}
      <HistoryList onEdit={handleEdit} bloc={bloc} />
      <UiDialog
        open={openDialog}
        setOpen={() => setOpenDialog(false)}
        maxWidth="lg"
      >
        <Content
          setOpen={(v: boolean) => setOpenDialog(v)}
          data={historial}
          bloc={bloc}
        />
      </UiDialog>
    </Container>
  );
};

export default HistorialAllContent;
